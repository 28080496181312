/**
 * External dependencies.
 */
import React from 'react';

/**
 * ThredsIcon Component.
 *
 * @return {jsx}
 */
const ThredsIcon = () => (
	<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M14.7428 9.26961C14.6566 9.22832 14.5691 9.18858 14.4805 9.15053C14.3261 6.30606 12.7718 4.6776 10.162 4.66094C10.1502 4.66086 10.1385 4.66086 10.1266 4.66086C8.56564 4.66086 7.26738 5.32718 6.46831 6.53966L7.90362 7.52425C8.50056 6.61857 9.43739 6.4255 10.1273 6.4255C10.1353 6.4255 10.1433 6.4255 10.1512 6.42557C11.0105 6.43105 11.6589 6.6809 12.0786 7.16813C12.384 7.52284 12.5883 8.01302 12.6895 8.63165C11.9276 8.50216 11.1036 8.46234 10.2228 8.51284C7.7415 8.65577 6.14634 10.1029 6.25347 12.1137C6.30784 13.1337 6.81598 14.0112 7.68424 14.5845C8.41835 15.0691 9.36383 15.306 10.3465 15.2524C11.6441 15.1813 12.6622 14.6861 13.3724 13.7808C13.9117 13.0933 14.2529 12.2024 14.4035 11.0798C15.022 11.453 15.4803 11.9442 15.7334 12.5346C16.1638 13.5382 16.1889 15.1875 14.8432 16.5321C13.6641 17.71 12.2468 18.2196 10.1049 18.2353C7.72895 18.2177 5.93204 17.4557 4.76373 15.9705C3.66971 14.5798 3.10432 12.571 3.08322 10C3.10432 7.42894 3.66971 5.42017 4.76373 4.02945C5.93204 2.54426 7.72892 1.78229 10.1049 1.76464C12.4981 1.78243 14.3263 2.54806 15.5393 4.04042C16.1341 4.77225 16.5826 5.69258 16.8782 6.76566L18.5602 6.3169C18.2018 4.99606 17.638 3.85789 16.8707 2.91396C15.3156 1.0007 13.0412 0.0203279 10.1108 0H10.099C7.17452 0.0202573 4.92561 1.00435 3.4148 2.92493C2.07038 4.634 1.37689 7.01205 1.35359 9.99297L1.35352 10L1.35359 10.007C1.37689 12.9879 2.07038 15.366 3.4148 17.0751C4.92561 18.9956 7.17452 19.9798 10.099 20H10.1108C12.7108 19.982 14.5435 19.3013 16.0533 17.7928C18.0286 15.8194 17.9691 13.3457 17.3181 11.8272C16.851 10.7382 15.9605 9.85377 14.7428 9.26961ZM10.2536 13.4903C9.16607 13.5516 8.03627 13.0634 7.98056 12.0179C7.93927 11.2427 8.53224 10.3777 10.3203 10.2747C10.5251 10.2629 10.726 10.2571 10.9234 10.2571C11.5729 10.2571 12.1805 10.3202 12.7329 10.4409C12.5268 13.0141 11.3183 13.4319 10.2536 13.4903Z" fill="black" />
	</svg>
);

export default ThredsIcon;
