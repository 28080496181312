/**
 * External dependencies.
 */
import React, { useState } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { useCookies } from 'react-cookie';
import { collectInnerBlocks } from '@blocks/MapBlocks/MapBlocks';
import { parse } from '@wordpress/block-serialization-default-parser';

/**
 * Internal dependencies.
 */
import './style.scss';

/**
 * Content Component.
 *
 * @param {Object} page Page props.
 *
 * @return {jsx}
 */

const Banner = (props) => {
	const { query: { OWGraphQL: { reusableBlocks } }, data } = props;
	// console.log(props)
	// return (<></>);
	const { contentRaw, content, slug: COOKIE_NAME } = data[0];
	const [isShow, setShow] = useState(true);
	const [cookies, setCookie] = useCookies([COOKIE_NAME]);
	const isBannerClosed = cookies[COOKIE_NAME] === 'true';
	// console.log(contentRaw)
	const handleBanner = () => {
		setShow(false);
		setCookie(COOKIE_NAME, true)
	}
	const parsedContent = parse(contentRaw ?? '');

	let layout = collectInnerBlocks(parsedContent, reusableBlocks, parsedContent, content);

	if (isShow && !isBannerClosed) {
		return (
			<section className="banner-section">
				<div className='banner-section-inner'>
					{layout && layout[0] && layout[0]}
					{/* eslint-disable-next-line */}
					<span className="banner-close-btn" onClick={handleBanner} onKeyDown={() => { }}>
						<svg width="34" height="35" viewBox="0 0 34 35" fill="none" xmlns="http://www.w3.org/2000/svg">
							<line x1="33.3536" y1="1.35355" x2="0.353553" y2="34.3536" stroke="white" />
							<line x1="0.353553" y1="0.646447" x2="33.3536" y2="33.6464" stroke="white" />
						</svg>
					</span>
				</div>

			</section>
		);
	} else {
		return (<></>)
	}

};


export default (props) => {
	return (
		<StaticQuery
			query={graphql`
					query BanerQuery {
						OWGraphQL {
							reusableBlocks(first: 5000) {
								edges {
									node {
									content
									id
									}
								}
							}
						}
					}
				`}
			render={query => {
				return <Banner query={query} {...props} />
			}}
		/>
	)
}
