/**
 * External dependencies.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql, Link } from 'gatsby';
// import ContactForm from '@blocks/contact-form';

/**
 * Internal dependencies.
 */
import Column from './column';
import SocialLinks from './social-links';
import { flatListToHierarchical } from '../../utils/functions';

import './style.scss';
import FooterLogo from '../icons/footer-logo';
//import CookiePolicy from "../cookie-policy"; TODO Temporarily removing

/**
 * Footer Component.
 *
 * @param {Object} props Component props.
 *
 * @return {jsx}
 */

const Footer = ({ data }) => {

	const {
		footer: { copyrightText, brandLogos, socialLinks },
		footerMenuColOne,
		footerMenuColTwo,
		footerMenuColThree,
		footerMenuColFour
	} = data.OWGraphQL;

	const date = new Date();

	return (
		<footer className="site-footer-container">
			{/* <ContactForm /> */}
			<div className="site-footer__top row">
				<div className="site-footer__social-links-column small-12 columns">
					<SocialLinks items={socialLinks} />
				</div>
				<div className="site-footer__divider-top small-12 columns">
					<div className="divider" />
				</div>
				<div className="site-footer__logo-column small-12 columns show-for-small-only">
					<Link to="/" className="site-footer__logo-link">
						<FooterLogo />
					</Link>
				</div>
				<Column className="footer-menu-column small-12 medium-5 large-3 columns" menus={footerMenuColOne ? flatListToHierarchical(footerMenuColOne.nodes) : ''} />
				<Column className="footer-menu-column small-12 medium-5 large-3 columns" menus={footerMenuColTwo ? flatListToHierarchical(footerMenuColTwo.nodes) : ''} />
				<Column className="footer-menu-column small-12 medium-5 large-3 columns" menus={footerMenuColThree ? flatListToHierarchical(footerMenuColThree.nodes) : ''} />
				<Column className="footer-menu-column small-12 medium-5 large-3 columns" menus={footerMenuColFour ? flatListToHierarchical(footerMenuColFour.nodes) : ''} hasLoginLink={true} />

			</div>
			<div className="site-footer__bottom row">
				<div className="site-footer__divider-bottom small-12 column">
					<div className="divider small-12 column" />
				</div>
				<ul className="site-footer__brand-logos small-12 medium-6 large-4 column">
					{brandLogos.map(brandLogo => (
						<li className="site-footer__brand-logo-item" key={brandLogo.brandName}>
							<img className="site-footer__brand-logo" src={brandLogo.brandImage} alt={brandLogo.brandName} />
						</li>
					))}
				</ul>
				<div className="site-footer__copyright-text small-12 medium-6 large-8 column">
					{copyrightText} {date.getFullYear()}
				</div>
				{/*<CookiePolicy /> TODO Temporarily removing */}
			</div>
		</footer>
	);
}

Footer.propTypes = {
	data: PropTypes.object,
};

Footer.defaultProps = {
	data: {},
};

export {
	Footer
}

export default () => {
	return (
		<StaticQuery
			query={graphql`
				query FooterQuery {
					OWGraphQL {
						footer: getFooter {
						copyrightText
						footerLogo
						brandLogos {
							brandName
							brandImage
						}
						socialLinks {
							iconUrl
							iconName
						}
						}
						footerMenuColOne: menuItems(where: {location: OW_MENU_FOOTER_COL_1}) {
							nodes {
								key: id
								parentId
								label
								url
								path
							}
						}
						footerMenuColTwo: menuItems(where: {location: OW_MENU_FOOTER_COL_2}) {
							nodes {
								key: id
								parentId
								label
								url
								path
							}
						}
						footerMenuColThree: menuItems(where: {location: OW_MENU_FOOTER_COL_3}) {
							nodes {
								key: id
								parentId
								label
								url
								path
							}
						}
						footerMenuColFour: menuItems(where: {location: OW_MENU_FOOTER_COL_4}) {
							nodes {
								key: id
								parentId
								label
								url
								path
							}
						}
					}
				}
			`}
			render={data => <Footer data={data} />}
		/>
	)
};
