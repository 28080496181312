/**
 * External dependencies.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { Link } from 'gatsby';
import classnames from 'classnames';

/**
 * Internal dependencies.
 */
import { isExternalUrl } from "../../utils/functions";

/**
 * Column Component.
 *
 * @param {Object} props Component props.
 *
 * @return {jsx}
 */
const Column = ({ menus, className, hasLoginLink }) => {

	return (
		<div className={className}>
			{menus.map((menu, index) => {
				return (
					<div key={`${menu.key}-${index}`} className="footer-menu-column__row">
						<h3 className="footer-menu-column__menu-title">
							{'#' === menu.path ? (
								<span className="footer-menu-column__menu-title-link">{`${menu.label}`}</span>
							) : isExternalUrl(menu.path) ? (
								<a href={menu.path} target="_blank" rel="noreferrer" className="footer-menu-column__menu-title-link">
									{menu.label}
								</a>
							) : (
								<Link to={menu.path} className="footer-menu-column__menu-title-link" >
									{menu.label}
								</Link>
							)}
						</h3>
						{!isEmpty(menu.childItems) && (
							<ul className="footer-menu-column__menu">
								{menu.childItems.map((child, index) => {
									const isLevel3 = 0 === child.label.indexOf('-');
									const childMenuItemClassNames = classnames('footer-menu-column__menu-item', {
										'footer-menu-column__menu-item--level-3': isLevel3,
									});

									return (
										<li className={`${childMenuItemClassNames}`} key={`${child.id}-${index}`} >
											{isExternalUrl(child.path) ? (
												<a className="footer-menu-column__menu-link" target="_blank" rel="noopener noreferrer" href={child.path}>
													{isLevel3 ? child.label.trim().replace('- ', '') : child.label}
												</a>
											) : (
												<Link to={child.path} className="footer-menu-column__menu-link" >
													{isLevel3 ? child.label.trim().replace('- ', '') : child.label}
												</Link>
											)}
										</li>
									);
								})}
							</ul>
						)}
					</div>
				)
			})}



		</div>
	);
};

Column.propTypes = {
	menus: PropTypes.array,
	className: PropTypes.string,
	hasLoginLink: PropTypes.bool
};

Column.defaultProps = {
	menus: [],
	hasLoginLink: false
}

export default Column;
