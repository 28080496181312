/**
 * External dependencies.
 */
import React from 'react';
import PropTypes from 'prop-types';

/**
 * Internal dependencies.
 */
import FacebookIcon from '../../icons/facebook-icon';
import TwitterIcon from '../../icons/twitter-icon';
import LinkedinIcon from '../../icons/linkedin-icon';
import ThredsIcon from '../../icons/threds-icon';
import InstagramIcon from '../../icons/instagram-icon';

import './style.scss';

const SocialLinks = ({ items }) => {

	const socialIcons = {
		// facebook: <FacebookIcon />,
		// twitter: <TwitterIcon />,
		linkedin: <LinkedinIcon />,
		threds: <ThredsIcon />
		// instagram: <InstagramIcon />
	};

	return (
		<div className="site-footer__social-links-wrap">
			<ul className="site-footer__social-links">
				{items.map(socialLink => (
					<li key={socialLink.iconName}>
						<a className={`${socialLink.iconName}-icon-link`} title={socialLink.iconName} target="__blank" href={socialLink.iconUrl}>
							{socialIcons[socialLink.iconName]}
						</a>
					</li>
				))}
			</ul>
		</div>
	)
}

SocialLinks.propTypes = {
	items: PropTypes.array,
};

SocialLinks.defaultProps = {
	items: []
};

export default SocialLinks;
