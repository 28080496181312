/**
 * Layout component contains header and footer
 *
 * @package ow-website
 */

/**
 * External dependencies.
 */
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import 'intersection-observer'; // polyfill.

// import { PopUp } from '@components/pop-up';

/**
 * Internal styles.
 * Must be imported before internal components for CSS priorities.
 */
import './../../sass/common.scss';
import './style.scss';

/**
 * Internal dependencies.
 */
import { useTheme } from '../../hooks';
import Header from '../header';
import Footer from '../footer';

/**
 * Layout Component.
 *
 * @param {Object} props Component props.
 *
 * @return {jsx}
 */
const Layout = (props) => {
	const { children, title, className, uri, theme } = props;
	// console.log(props)
	const containerClass = classnames('page-layout', className);

	// Change page theme.
	useTheme(theme);

	return (
		<div className={containerClass} >
			{/* <PopUp uri={uri} /> */}
			<Header title={title} uri={uri} />
			<main className="main-container row">
				<div className="small-12 column">
					{children}
				</div>
			</main>
			<Footer />
		</div>
	);
};



Layout.propTypes = {
	children: PropTypes.node.isRequired,
	title: PropTypes.string,
	className: PropTypes.string,
	uri: PropTypes.string,
	theme: PropTypes.string
};

Layout.defaultProps = {
	children: {},
	title: 'OpenWeb',
	theme: 'light',
};

export default Layout;
