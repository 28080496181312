
/**
 * External dependencies.
 */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql, Link } from 'gatsby';

/**
 * Internal dependencies.
 */
import Nav from './nav';
import SiteLogo from '../icons/site-logo';
import { flatListToHierarchical } from '../../utils/functions';
import Banner from '@components/banner';

import './style.scss';

/**
 * Header Component.
 *
 * @param {Object} data Component props.
 *
 * @return {jsx}
 */
const Header = ({ data }) => {
	const [menuVisible, setMenuVisibility] = useState(false);
	const { header: { siteTitle, siteTagLine }, headerMenuItems, banners } = data.OWGraphQL;

	const activeBanner = banners.nodes.filter((item) => item.active === 'true');

	const toggleMenu = () => {
		setMenuVisibility(!menuVisible);
		document.body.classList.toggle('mobile-menu-open');
	};

	const hideMenu = () => {
		if (menuVisible) {
			toggleMenu();
		}
	};

	return (
		<>
			{activeBanner && activeBanner.length > 0 && <Banner data={activeBanner} />}
			<header className="header-container">
				<div className="row">
					<div className="site-header small-12 column">
						<div className="site-header__container">
							<div className="site-brand">
								<Link to="/" className="site-brand__link" onClick={hideMenu} >
									<SiteLogo />
									<h2 className="screen-reader-text site-brand__title">{siteTitle}</h2>
									<p className="site-brand__description">{siteTagLine}</p>
								</Link>
							</div>
							<Nav
								headerMenuItems={flatListToHierarchical(headerMenuItems.nodes)}
								menuVisible={menuVisible}
								toggleMenu={toggleMenu}
							/>
						</div>
					</div>
				</div>
			</header>
		</>
	);
}

Header.propTypes = {
	data: PropTypes.object,
	uri: PropTypes.string
};

Header.defaultProps = {
	data: {
		OWGraphQL: {}
	},
	uri: '',
};

export {
	Header
};

export default (props) => {

	return (
		<StaticQuery
			query={graphql`
					query HeaderQuery {
						OWGraphQL {
							header: getHeader {
								siteLogoUrl
								siteTagLine
								siteTitle
							}
							headerMenuItems: menuItems(where: {location: OW_MENU_HEADER}, first: 50) {
								nodes {
									key: id
									parentId
									label
									path
									url
								}
							}
							banners(first: 10) {
								nodes {
									active
									id
									contentRaw
									content
									slug
								}
							}
						}
					}
				`}
			render={data => {
				return <Header data={data} uri={props.uri} />
			}}
		/>
	)
}